<template>
	<div class="content flex-space-between">
		<div class="left">
			<AsideNavMenu
				:isArticle="isArticle"
				:list="navMenuList"
				title="通知公告"
				@navMenuClick="navMenuClick"
			></AsideNavMenu>
		</div>
		<div class="right">
			<router-view :index="navIndex"></router-view>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import AsideNavMenu from '@/components/aside/navmenu.vue'

export default {
	components: {AsideNavMenu},
	data() {
		return {
			navMenuList: [
				{
					name: '新闻媒体',
					url: '/nelist'
				},
				{
					name: '协会动态',
					url: '/nelist'
				},
				{
					name: '赛事速讯',
					url: '/nelist'
				}
			],
			navIndex: 0,
			isArticle: false
		}
	},

	mounted() {
		EventBus.$on('selectClick', (res) => {
			this.navIndex = res.index
		})
	},

	methods: {
		navMenuClick(index) {
			this.navIndex = index
			this.$route.meta.asideNavMenuIndex = index
			if (index == 0) {
				this.$route.meta.pathName = '新闻媒体'
			} else if (index == 1) {
				this.$route.meta.pathName = '协会动态'
			} else if (index == 2) {
				this.$route.meta.pathName = '赛事速讯'
			}
		}
	},
	watch: {
		$route(val) {
			if (val.path == '/nedetail') {
				this.isArticle = true
			} else {
				this.isArticle = false
			}
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	align-items: flex-start;
}

.right {
	flex: 1;
	margin-left: 20px;
	background: #fff;
	border-radius: 3px;
}
</style>
